// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBRs415MkOq8m7YNy1zsQ9gDg9hoOa0znM',
  authDomain: 'eass-353614.firebaseapp.com',
  projectId: 'eass-353614',
  storageBucket: 'eass-353614.appspot.com',
  messagingSenderId: '706279032943',
  appId: '1:706279032943:web:5d9f5181989d8ba992e59a',
  measurementId: 'G-SWFREWH35M'
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export {
  firebaseApp,
  analytics
};
