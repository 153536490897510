import React, { useState, useEffect } from 'react';
import './App.css';
import MainMint from './MainMint';
import NavBar from './NavBar';
import Earn from './Earn';
import Earnings from './Earnings';
import About from './About';
import Disclaimer from './Disclaimer';
import Team from './Team';
import Gallery from './Gallery';
import FourK from './4K';
import Style from './Style';

import { logEvent } from 'firebase/analytics';
import { analytics } from './Firebase.js';

import Const from './Constants';

function App() {
  const [config, setConfig] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [appMode, setAppMode] = useState(Const.sMint);
  const [refKey, setRefKey] = useState(''); // This is own ref key, not the one passed as a URL.
  const [globalToken, setGlobalToken] = useState('');
  let [reportedURLView, setReportedURLView] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const getConfig = async () => {
    try {
      // Always fetching config from prod.
      const theURL = 'https://eass-back-f7cdjaxb3a-uc.a.run.app/config';
      // const theURL = 'http://localhost:8080/config';
      const response = await fetch(theURL);
      const data = await response.json();
      setConfig(data);
    } catch (error) {
      logEvent(analytics, 'error_get_config');
    }
  };

  async function handleReportedURLView() {
    try {
      logEvent(analytics, 'refkey_view');

      // Get referral key from the window URL
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
      });

      if(params.k) {
        reportedURLView = true;
        setReportedURLView(true);

        const theURL = (config.prodMode ? config.baseURL : config.localBaseURL) + '/refkey/view';
        await fetch(theURL, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            refKey: params.k
          })
        });
      }
    } catch (error) {
      logEvent(analytics, 'error_refkey_view');
    }
  }

  function validPath(pathname) {
    let appmode = '';

    if(pathname === '/mint') {
      appmode = Const.sMint;
    } else if(pathname === '/earn') {
      appmode = Const.sEarn;
    } else if(pathname === '/about') {
      appmode = Const.sAbout;
    } else if(pathname === '/disclaimer') {
      appmode = Const.sDisclaimer;
    } else if(pathname === '/gallery') {
      appmode = Const.sGallery;
    } else if(pathname === '/4K') {
      appmode = Const.s4K;
    } else if(pathname === '/ready') {
      appmode = Const.sStyle;
    } else {
      return { valid: false };
    }

    return {
      valid: true,
      appMode: appmode
    };
  }

  function setPathAsAppMode(pathname) {
    const res = validPath(pathname);
    if(res.valid === true) {
      setAppMode(res.appMode);
    }
  }

  // Simple routing
  useEffect(() => {
    setPathAsAppMode(window.location.pathname);
  }, []);

  // When app component mounts request the config from the server.
  useEffect(() => {
    // Get config from server
    getConfig();
  }, []);

  useEffect(() => {
    // Check if we received the config already.
    if(Object.keys(config).length > 0) {
      if(!reportedURLView) {
        handleReportedURLView();
      }
    }
  }, [config]);

  // And this one is for resize and setting mobile background
  useEffect(() => {
    const updateBackground = () => {
      // Set a breakpoint for mobile screen width
      if(window.innerWidth < 780) { // Chakra UI Mobile screen width
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Call the function when the component mounts
    updateBackground();

    // Update the background color when the window is resized
    window.addEventListener('resize', updateBackground);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('resize', updateBackground);
  }, []);

  return (
    <div className="overlay">
      <div className="App">
        <NavBar
          accounts={accounts} setAccounts={setAccounts}
          appMode={appMode} setAppMode={setAppMode}
          refKey={refKey} setRefKey={setRefKey}
          globalToken={globalToken} setGlobalToken={setGlobalToken}
          config={config} />
        {
          appMode === Const.sMint
            ? (<MainMint accounts={accounts} setAppMode={setAppMode} globalToken={globalToken} setGlobalToken={setGlobalToken} isMobile={isMobile} config={config} />)
            : (
              appMode === Const.sEarnings
                ? (<Earnings globalToken={globalToken} config={config} />)
                : (
                  appMode === Const.sEarn
                    ? (<Earn accounts={accounts} refKey={refKey} />)
                    : (
                      appMode === Const.sAbout
                        ? (<About/>)
                        : (
                          appMode === Const.sDisclaimer
                            ? (<Disclaimer/>)
                            : (
                              appMode === Const.sTeam
                                ? (<Team/>)
                                : (
                                  appMode === Const.sGallery
                                    ? (<Gallery setAppMode={setAppMode}/>)
                                    : (
                                      appMode === Const.s4K
                                        ? (<FourK/>)
                                        : (
                                          appMode === Const.sStyle
                                            ? (<Style/>)
                                            : null
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        }
      </div>
      { !isMobile ? (<div className="desktop-background"/>) : (<div className="mobile-background"/>) }
    </div>
  );
}

export default App;
