import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import Const from './Constants';

const Disclaimer = () => {
  const text = `By minting your Earth Alien you acknowledge and accept full responsibility for your actions and any associated risks.

  Our team is passionately devoted to the collection's success, but we do not offer financial advice or assume liability for any harm, including financial loss, related to the project.
  
  We cannot guarantee financial gain, nor can we assure that all aspects of our roadmap or vision will be realized in their entirety or in part.
  
  By engaging with our project, you agree that the team is not responsible for any risk, loss, or harm associated with Earth Aliens NFT collection.
  `;

  return (
    <Flex justify="center" align="center" height="100vh" paddingBottom="150px">
      <Box>
        <Text fontSize="24px" textShadow="0 5px #000000">{Const.sDisclaimer}</Text>
        <Box
          width="100%"
          maxHeight="75%"
          overflowY="auto"
          justify="center"
          align="center"
        >
          <Text
            marginTop={10}
            fontSize={{ base: '14px', md: '18px' }}
            fontFamily={Const.sFontSecond}
            width={{ base: '94%', md: '80%' }}
            textAlign="left"
            textShadow="0 2px 2px #000000"
            whiteSpace="pre-line">
            { text }
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default Disclaimer;
