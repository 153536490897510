const Const = {
  kMaxSupply: 10000,
  sMint: 'Mint',
  sEarn: 'Earn',
  sEarnings: 'Earnings',
  sAbout: 'About',
  sDisclaimer: 'Disclaimer',
  sWhitepaper: 'Whitepaper',
  sGallery: 'Gallery',
  sTeam: 'Team',
  s4K: '4K',
  sStyle: 'Ready',
  sHtml: 'Html',
  sRefURLBase: 'https://earthaliens.net/?k=',
  sFontSecond: 'Roboto Mono',
  kHeaderFontSize: '40px',
  kTokenExpireTime: '24h',
  kWhite: '#ffffff',
  kYellow: '#f4fd50',
  kGreen: '#75eb04',
  kDarkGreen: '#183000'
};

export default Const;
