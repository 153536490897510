import React, { useState } from 'react';
import { Box, Image } from '@chakra-ui/react';

const ImageMagnifier = ({
  src,
  width,
  height,
  magnifierHeight = 100,
  magnifierWidth = 100,
  zoomLevel = 1.5
}) => {
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);

  return (
    <Box position="relative" width={width} height={height}>
      <Image
        src={src}
        width={width}
        height={height}
        borderRadius="6px"
        onMouseEnter={(e) => {
          const elem = e.currentTarget;
          const { width, height } = elem.getBoundingClientRect();
          setSize([width, height]);
          setShowMagnifier(true);
        }}
        onMouseMove={(e) => {
          const elem = e.currentTarget;
          const { top, left } = elem.getBoundingClientRect();
          const x = e.pageX - left - window.pageXOffset;
          const y = e.pageY - top - window.pageYOffset;
          setXY([x, y]);
        }}
        onMouseLeave={() => {
          setShowMagnifier(false);
        }}
        onTouchStart={(e) => {
          // e.preventDefault();
          const elem = e.currentTarget;
          const { width, height, top, left } = elem.getBoundingClientRect();
          const x = e.touches[0].pageX - left - window.pageXOffset;
          const y = e.touches[0].pageY - top - window.pageYOffset;
          setXY([x, y]);
          setSize([width, height]);
          setShowMagnifier(true);
        }}
        onTouchEnd={() => {
          setShowMagnifier(false);
        }}
        onTouchMove={(e) => {
          // e.preventDefault(); Can try adding Box instead of an image
          const elem = e.currentTarget;
          const { top, left } = elem.getBoundingClientRect();
          const x = e.touches[0].pageX - left - window.pageXOffset;
          const y = e.touches[0].pageY - top - window.pageYOffset;
          setXY([x, y]);
        }}
        alt="img"
      />

      {showMagnifier && (
        <Box
          position="absolute"
          pointerEvents="none"
          height={`${magnifierHeight}px`}
          width={`${magnifierWidth}px`}
          top={`${y - magnifierHeight / 2}px`}
          left={`${x - magnifierWidth / 2}px`}
          borderRadius="50%"
          border="2px solid white"
          bgImage={`url('${src}')`}
          bgRepeat="no-repeat"
          bgSize={`${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`}
          bgPosition={`${-x * zoomLevel + magnifierWidth / 2}px ${-y * zoomLevel + magnifierHeight / 2}px`}
        />
      )}
    </Box>
  );
};

export default ImageMagnifier;
