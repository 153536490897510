import React from 'react';
import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
import { logEvent } from 'firebase/analytics';
import { analytics } from './Firebase.js';

import Const from './Constants';

const Earn = ({ accounts, refKey }) => {
  const isConnected = Boolean(accounts[0]);

  const handleCopy = () => {
    try {
      logEvent(analytics, 'handle_copy');

      navigator.clipboard.writeText(Const.sRefURLBase + refKey);
    } catch (error) {
      logEvent(analytics, 'error_handle_copy');
    }
  };

  return (
    <Flex justify="center" align="center" height="100vh" paddingBottom="150px">
      <Box width="600px">
        {isConnected
          ? (
            <div>
              <Text fontSize={Const.kHeaderFontSize} textShadow="0 5px #000000">Earn ETH</Text>
              <Text
                fontFamily={Const.sFontSecond}
                fontSize="18px"
                letterSpacing="-5.5%"
                textShadow="0 2px 2px #000000"
                whiteSpace="pre-line"
                marginTop={6}
                marginBottom={4}
              >
                {
                  `Share this URL with your network and get 10% from every mint your friends make! \n
                   Your friends will get 10% back on their wallet too.`
                }
              </Text>
            </div>
          )
          : (
            <div>
              <Text fontSize={Const.kHeaderFontSize} textShadow="0 5px #000000">Earn ETH</Text>
              <Text
                fontFamily={Const.sFontSecond}
                fontSize="18px"
                letterSpacing="-5.5%"
                textShadow="0 2px 2px #000000"
                whiteSpace="pre-line"
                marginTop={4}
              >
                Connect your wallet to get a special URL to share with your network and earn 10% from every mint!
              </Text>
            </div>
          )}

        {isConnected
          ? (
            <div>
              <Flex align="center" justify="center">
                <Input
                  readOnly
                  fontFamily={Const.sFontSecond}
                  fontSize="13"
                  width="420px"
                  height="40px"
                  textAlign="center"
                  marginTop="10px"
                  value={
                    refKey ? (Const.sRefURLBase + refKey) : 'Generating...'
                  }
                />
              </Flex>
              <Button
                backgroundColor={Const.kGreen}
                borderRadius="5px"
                boxShadow="0px 2px 2px 1px #0F0F0F"
                color={Const.kDarkGreen}
                cursor="pointer"
                fontSize="12px"
                fontWeight="normal"
                padding="15px"
                marginTop="10px"
                onClick={handleCopy}>
                Copy
              </Button>
            </div>
          )
          : (
            <Text
              marginTop="70px"
              fontSize="12px"
              letterSpacing="-5.5%"
              textShadow="0 3px #000000"
              color={Const.kGreen}
            >
              You must be connected to earn ETH
            </Text>
          )}
      </Box>
    </Flex>
  );
};

export default Earn;
