import React, { useState, useEffect } from 'react';
import { ethers, BigNumber } from 'ethers';
import {
  Box,
  Button,
  Flex,
  Input,
  Spacer,
  Text,
  Progress
} from '@chakra-ui/react';
import YouTube from 'react-youtube';

import Web3Token from 'web3-token';
import EarthAliensNFT from './EarthAliensNFT.json';

import { logEvent } from 'firebase/analytics';
import { analytics } from './Firebase.js';
import Const from './Constants';

const earthAliensNFTAddress = '0xbf97cAc6eAB8c65439dA93aA2e68c7ac8D1a9ead';

// Ref key states
const sNoRefKey = 'noRefKey';
const sValidRefKey = 'validRefKey';
const sInvalidRefKey = 'invalidRefKey';
const sOwnRefKey = 'ownRefKey';

// Mint states
const sMintStateNone = 'none';
const sMintStateProgress = 'progress';
const sMintStateFail = 'fail';
const sMintStateSuccess = 'success';

const MainMint = ({ accounts, setAppMode, globalToken, setGlobalToken, isMobile, config }) => {
  const [mintAmount, setMintAmount] = useState(1);
  const [validRefKey, setValidRefKey] = useState(sNoRefKey);
  const [mintState, setMintState] = useState(sMintStateNone);

  const isConnected = Boolean(accounts[0]);

  const videoEnabled = !isMobile && config.videoEnabled;

  // Get referral key from the window URL
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });

  async function checkRefKey() {
    try {
      logEvent(analytics, 'check_refkey');

      const theURL = (config.prodMode ? config.baseURL : config.localBaseURL) + `/refkey/check/?refKey=${params.k}`;
      const response = await fetch(theURL);
      const data = await response.json();
      if(isConnected && accounts[0] === data.account) {
        setValidRefKey(sOwnRefKey);
      } else {
        setValidRefKey(data.result);
      }
    } catch (error) {
      logEvent(analytics, 'error_check_refkey');
    }
  }

  useEffect(() => {
    // Check if we received the config already.
    if(params.k && Object.keys(config).length > 0) {
      checkRefKey();
    }
  }, [config]);

  async function handleMint() {
    try {
      if(window.ethereum) {
        logEvent(analytics, 'mint_action');

        if(!config.mintEnabled) {
          window.confirm('Mint is not enabled. Coming soon!');
          return;
        }

        const confirmText = 'Buy minting you agree with the disclaimer on this website.\n\nHappy minting!';

        if(!window.confirm(confirmText)) {
          logEvent(analytics, 'mint_disclaimer_canceled');
          return;
        }

        setMintState(sMintStateProgress);

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          earthAliensNFTAddress,
          EarthAliensNFT.abi,
          signer
        );

        const nftPrice = config.price;
        // eslint-disable-next-line no-unused-vars
        const response = await contract.mint(BigNumber.from(mintAmount), {
          value: ethers.utils.parseEther((nftPrice * mintAmount).toString())
        });

        // console.log('response code: ', response.code); // TODO: check if all good?
        // console.log('response message: ', response.message); // TODO: check if all good?

        // eslint-disable-next-line promise/param-names
        // await new Promise(r => setTimeout(r, 5000)); // Progress bar test

        await recordMintByReferral(nftPrice, mintAmount);

        setMintState(sMintStateSuccess);

        // After the mint, show the user the state of the app to suggest ref program.
        setAppMode(Const.sEarn);

        logEvent(analytics, 'mint_success');
      }
    } catch (err) {
      logEvent(analytics, 'error_mint');

      setMintState(sMintStateFail);
    }
  }

  async function isValidToken(token) {
    try {
      logEvent(analytics, 'is_valid_token');

      const { address, body } = await Web3Token.verify(token, {
        // verify that received token is signed only for our domain
        // domain: 'localhost'
        // domain: 'earthaliens.net'
      });
      return address && body;
    } catch (error) {
      logEvent(analytics, 'error_is_valid_token');
    }
    return false;
  }

  async function recordMintByReferral(nftPrice, mintAmount) {
    try {
      logEvent(analytics, 'record_mint_by_referral');

      // Connection to MetaMask wallet
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      if(!globalToken || !(await isValidToken(globalToken))) {
        // Generating a token with expiration time
        globalToken = await Web3Token.sign(async msg => await signer.signMessage(msg), {
          domain: 'earthaliens.net',
          statement: 'Sign in to earthaliens.net to create a unique referral URL. This request will not trigger a blockchain transaction or cost any gas fees.',
          expires_in: Const.kTokenExpireTime
        });

        setGlobalToken(globalToken);
      }

      const theURL = (config.prodMode ? config.baseURL : config.localBaseURL) + '/mint';
      await fetch(theURL, {
        method: 'POST',
        headers: {
          Authorization: globalToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          refKey: params.k,
          mintPrice: nftPrice,
          mintAmount
        })
      });
    } catch (error) {
      logEvent(analytics, 'error_record_mint_by_referral');
    }
  }

  const handleDecrement = () => {
    try {
      if(mintAmount <= 1) return;
      setMintAmount(mintAmount - 1);

      logEvent(analytics, 'mint_decrement');
    } catch (error) {
      logEvent(analytics, 'error_mint_decrement');
    }
  };

  const handleIncrement = () => {
    try {
      if(mintAmount >= Const.kMaxSupply) return;
      setMintAmount(mintAmount + 1);

      logEvent(analytics, 'mint_increment');
    } catch (error) {
      logEvent(analytics, 'error_mint_increment');
    }
  };

  const handleInputChange = (event) => {
    try {
      let amount = event.target.value;

      if(amount >= Const.kMaxSupply) {
        amount = Const.kMaxSupply;
      } else if(!amount || amount <= 0) {
        amount = 1;
      }
      setMintAmount(amount);

      logEvent(analytics, 'mint_input');
    } catch (error) {
      logEvent(analytics, 'error_mint_input');
    }
  };

  const youTubeOpts = {
    height: '400',
    width: '712',
    playerVars: {
      mute: 0,
      autoplay: 0,
      rel: 0
    }
  };

  return (
    <Flex justify="center" align="center" height="100vh" paddingBottom="150px">
      <Box width="720px">
        <div>
          <Text
            fontSize={Const.kHeaderFontSize}
            textShadow="0 5px #000000">
            Earth Aliens
          </Text>
          <Text
            fontSize="22px"
            fontFamily={Const.sFontSecond}
            letterSpacing="-5.5%"
            textShadow="0 2px 2px #000000"
            whiteSpace="pre-line"
            marginTop={6}
            marginBottom={6}
          >
            { 'Join the otherworldly.\nMint your exclusive Earth Alien NFT soon!' }
          </Text>
        </div>

        {isConnected
          ? (
            <div>
              <Flex align="center" justify="center">
                <Button
                  backgroundColor={Const.kGreen}
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color={Const.kDarkGreen}
                  cursor="pointer"
                  fontSize="12px"
                  fontWeight="normal"
                  padding="13px"
                  marginTop="10px"
                  onClick={ handleDecrement }>
                  -
                </Button>
                <Input
                  type="number"
                  fontSize="14px"
                  fontWeight="normal"
                  width="100px"
                  height="40px"
                  textAlign="center"
                  marginTop="10px"
                  value={ mintAmount }
                  onChange={handleInputChange}
                />
                <Button
                  backgroundColor={Const.kGreen}
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color={Const.kDarkGreen}
                  cursor="pointer"
                  fontSize="13px"
                  fontWeight="normal"
                  padding="12px"
                  marginTop="10px"
                  onClick={ handleIncrement }>
                  +
                </Button>
              </Flex>
              <Button
                backgroundColor={Const.kGreen}
                borderRadius="5px"
                boxShadow="0px 2px 2px 1px #0F0F0F"
                color={Const.kDarkGreen}
                cursor="pointer"
                fontSize="12px"
                fontWeight="normal"
                padding="15px"
                marginTop="10px"
                marginBottom={4}
                onClick={handleMint}>
                Mint Now
              </Button>

              { mintState === sMintStateProgress
                ? (<Progress size='xs' isIndeterminate marginTop="25px" colorScheme='green' />)
                : (<Spacer />) }
              {
                mintState === sMintStateFail
                  ? (
                    <Text
                      fontSize="12px"
                      letterSpacing="-5.5%"
                      textShadow="0 2px 2px #000000"
                      color={Const.kYellow}
                      marginBottom={6}>
                      { 'Mint failed' }
                    </Text>
                  )
                  : null
              }
            </div>
          )
          : (
            <Text
              marginTop="70px"
              fontSize="12px"
              letterSpacing="-5.5%"
              textShadow="0 3px #000000"
              color={Const.kGreen}
            >
              You must be connected to Mint
            </Text>
          )}

        <Spacer padding="10px" />

        {validRefKey === sValidRefKey
          ? (
            <Text
              fontSize="12px"
              color={Const.kYellow}
              letterSpacing="-5.5%"
              textShadow="0 2px 2px #000000"
              marginBottom={6}
            >
              { '* You\'ve been referred and will get 10% back on your wallet once collection is fully minted' }
            </Text>
          )
          : (validRefKey === sInvalidRefKey
            ? (
              <Text
                fontSize="12px"
                letterSpacing="-5.5%"
                textShadow="0 2px 2px #000000"
                color={Const.kYellow}
                marginBottom={6}
              >
                { '* Invalid referral key' }
              </Text>)
            : (
              (validRefKey === sOwnRefKey)
                ? (
                  <Text
                    fontSize="12px"
                    letterSpacing="-5.5%"
                    textShadow="0 2px 2px #000000"
                    color={Const.kYellow}
                    marginBottom={6}
                  >
                    { '* Can\'t use own referral key' }
                  </Text>)
                : (<Spacer />)
            )
          )
        }
        { videoEnabled ? (<YouTube videoId="m2ykcv23LyU" opts={youTubeOpts} />) : (<Spacer />) }
      </Box>
    </Flex>
  );
};

export default MainMint;
