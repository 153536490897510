import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import Const from './Constants';

const About = () => {
  const text = `Dive into a captivating world of Earth Aliens! Featuring an exclusive collection of 10,000 intricate 3D-modeled, 4K alien art pieces crafted by a celebrated Apple Design Award Winning Artist.

  These unique masterpieces are handcrafted and algorithmically generated to reside securely on the Ethereum blockchain, ensuring the exclusivity and rarity of each Earth Alien.
  
  Our team meticulously designed every aspect of each Alien, incorporating striking details in 4K resolution and diverse properties to create a dazzling, unparalleled collection that transcends the boundaries of digital art.
  
  At the heart of Earth Aliens universe is our innovative, community-centric vision for the future of art and digital collectibles. We are deeply committed to nurturing the bond between our art and its collectors, fostering a vibrant and engaged community.
  
  Embark on this interstellar journey with us and become a part of the captivating world of Earth Aliens - where art, technology, and imagination converge to create an extraordinary experience.
  `;

  return (
    <Flex justify="center" align="center" height={{ base: '', md: '100vh' }} paddingBottom={{ base: '', md: '150px' }}>
      <Box
        justify="center"
        align="center"
      >
        <Text fontSize="24px" textShadow="0 5px #000000">{Const.sAbout}</Text>
        <Box
          justify="center"
          align="center">
          <Box
            width="90%"
            maxHeight={{ base: '80vh', md: '80vh' }}
            overflowY="auto">
            <Text
              marginTop={10}
              fontSize={{ base: '13px', md: '18px' }}
              fontFamily={Const.sFontSecond}
              textAlign="left"
              textShadow="0 2px 2px #000000"
              whiteSpace="pre-line">
              { text }
            </Text>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default About;
