import React, { useState, useEffect } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { logEvent } from 'firebase/analytics';
import { analytics } from './Firebase.js';

import Const from './Constants';

const Earnings = ({ globalToken, config }) => {
  const [earningsData, setEarningsData] = useState({
    account: '',
    refKey: '',
    views: 0,
    numberOfMints: 0,
    totalEth: 0,
    totalEarningsInEth: 0,
    totalEarningsInUSD: 0,
    totalDiscountEarnedForOthers: 0
  });

  useEffect(() => {
    async function getEarningsData() {
      try {
        logEvent(analytics, 'get_earnings_data');

        const theURL = (config.prodMode ? config.baseURL : config.localBaseURL) + '/refkey/earnings';
        const response = await fetch(theURL, {
          headers: new Headers({
            Authorization: globalToken
          })
        });
        const data = await response.json();
        setEarningsData(data);
      } catch (error) {
        logEvent(analytics, 'error_get_earnings_data');
      }
    }

    if(globalToken) {
      getEarningsData();
    }
  }, []);

  return (
    <Flex justify="center" align="center" height="100vh" paddingBottom="150px">
      <Box width="420px">
        {
          <div>
            <Text fontSize={Const.kHeaderFontSize} textShadow="0 5px #000000">Earnings</Text>
            <Text
              fontFamily={Const.sFontSecond}
              fontSize="18px"
              letterSpacing="-5.5%"
              textShadow="0 2px 2px #000000"
              marginTop={6}
              marginBottom={4}
            >
              { 'Below is the earning report for your dedicated referral URL' }
            </Text>
          </div>
        }
        <Box bg={Const.kDarkGreen} p={6} borderRadius={12}>
          {
            <Flex align="center" justify="center">
              <Text
                fontSize="11"
                width="420px"
                textAlign="left"
                color={Const.kYellow}
                textShadow="0 2px 2px #000000"
              >
                { `Referral key: ${earningsData.refKey}` }
              </Text>
            </Flex>
          }
          {
            <Flex align="center" justify="center">
              <Text
                marginTop={3}
                fontSize="11"
                width="420px"
                textAlign="left"
                color={Const.kYellow}
                textShadow="0 2px 2px #000000"
              >
                { `Number of views: ${earningsData.views}` }
              </Text>
            </Flex>
          }
          {
            <Flex align="center" justify="center">
              <Text
                marginTop={3}
                fontSize="11"
                width="420px"
                textAlign="left"
                color={Const.kYellow}
                textShadow="0 2px 2px #000000"
              >
                { `Number of mints: ${earningsData.numberOfMints}` }
              </Text>
            </Flex>
          }
          {
            <Flex align="center" justify="center">
              <Text
                marginTop={10}
                fontSize="11"
                width="420px"
                textAlign="left"
                color={Const.kGreen}
                textShadow="0 2px 2px #000000"
              >
                { `Your earnings: ${earningsData.totalEarningsInEth} ETH ~ ${earningsData.totalEarningsInUSD} USD` }
              </Text>
            </Flex>
          }
        </Box>
      </Box>
    </Flex>
  );
};

export default Earnings;
