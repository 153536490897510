import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import Const from './Constants';

import ImageMagnifier from './ImageMagnifier';

const FourK = () => {
  return (
    <Flex justify="center" align="center" height="100vh" paddingBottom={{ base: '300px', md: '120px' }}>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Text fontSize="24px" textShadow="0 5px #000000">{Const.s4K}</Text>
        <Text
          fontFamily={Const.sFontSecond}
          fontSize="14px"
          textShadow="0 2px 2px #000000"
          whiteSpace="pre-line"
          marginTop={1}
          marginBottom={4}
          display={{ base: 'none', md: 'flex' }}
        >
          {'Hover to experience exquisite detail in 4K resolution.'}
        </Text>
        <Text
          fontFamily={Const.sFontSecond}
          fontSize="14px"
          textShadow="0 2px 2px #000000"
          whiteSpace="pre-line"
          marginTop={1}
          marginBottom={4}
          display={{ md: 'none' }}
        >
          {'Hover to experience in 4K.'}
        </Text>
        <ImageMagnifier
          width={{ base: '96vw', md: '80vh' }}
          height={{ base: '96vw', md: '80vh' }}
          src={'https://storage.googleapis.com/resource.earthaliens.net/gallery/4K.png'}
          zoomLevel={5}
          magnifierHeight={300}
          magnifierWidth={300}
        />
      </Flex>
    </Flex>
  );
};

export default FourK;
