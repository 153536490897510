import React from 'react';
import {
  Text,
  Flex,
  Stack,
  HStack
} from '@chakra-ui/react';

import Const from './Constants';

function ModalAttributes({ attributes }) {
  return (
    <Flex justifyContent="flex-start" width="100%">
      <HStack spacing={6} alignItems={'start'}>
        <Stack>
          { attributes[0]
            ? (
              <HStack justifyContent="space-between">
                <Text fontSize={10} color={Const.kWhite}>{attributes[0].trait_type}:</Text>
                <Text fontSize={10} color={Const.kDarkGreen} bg={Const.kYellow} rounded={'md'} px={1}py={0.2}>{attributes[0].value}</Text>
              </HStack>
            )
            : null
          }
          { attributes[1]
            ? (
              <HStack justifyContent="space-between">
                <Text fontSize={10} color={Const.kWhite}>{attributes[1].trait_type}:</Text>
                <Text fontSize={10} color={Const.kDarkGreen} bg={Const.kYellow} rounded={'md'} px={1}py={0.2}>{attributes[1].value}</Text>
              </HStack>
            )
            : null
          }
          { attributes[2]
            ? (
              <HStack justifyContent="space-between">
                <Text fontSize={10} color={Const.kWhite}>{attributes[2].trait_type}:</Text>
                <Text fontSize={10} color={Const.kDarkGreen} bg={Const.kYellow} rounded={'md'} px={1}py={0.2}>{attributes[2].value}</Text>
              </HStack>
            )
            : null
          }
          { attributes[3]
            ? (
              <HStack justifyContent="space-between">
                <Text fontSize={10} color={Const.kWhite}>{attributes[3].trait_type}:</Text>
                <Text fontSize={10} color={Const.kDarkGreen} bg={Const.kYellow} rounded={'md'} px={1}py={0.2}>{attributes[3].value}</Text>
              </HStack>
            )
            : null
          }
          { attributes[4]
            ? (
              <HStack justifyContent="space-between">
                <Text fontSize={10} color={Const.kWhite}>{attributes[4].trait_type}:</Text>
                <Text fontSize={10} color={Const.kDarkGreen} bg={Const.kYellow} rounded={'md'} px={1}py={0.2}>{attributes[4].value}</Text>
              </HStack>
            )
            : null
          }
          { attributes[5]
            ? (
              <HStack justifyContent="space-between">
                <Text fontSize={10} color={Const.kWhite}>{attributes[5].trait_type}:</Text>
                <Text fontSize={10} color={Const.kDarkGreen} bg={Const.kYellow} rounded={'md'} px={1}py={0.2}>{attributes[5].value}</Text>
              </HStack>
            )
            : null
          }
        </Stack>
        <Stack>
          { attributes[6]
            ? (
              <HStack justifyContent="space-between">
                <Text fontSize={10} color={Const.kWhite}>{attributes[6].trait_type}:</Text>
                <Text fontSize={10} color={Const.kDarkGreen} bg={Const.kYellow} rounded={'md'} px={1}py={0.2}>{attributes[6].value}</Text>
              </HStack>
            )
            : null
          }
          { attributes[7]
            ? (
              <HStack justifyContent="space-between">
                <Text fontSize={10} color={Const.kWhite}>{attributes[7].trait_type}:</Text>
                <Text fontSize={10} color={Const.kDarkGreen} bg={Const.kYellow} rounded={'md'} px={1}py={0.2}>{attributes[7].value}</Text>
              </HStack>
            )
            : null
          }
          { attributes[8]
            ? (
              <HStack justifyContent="space-between">
                <Text fontSize={10} color={Const.kWhite}>{attributes[8].trait_type}:</Text>
                <Text fontSize={10} color={Const.kDarkGreen} bg={Const.kYellow} rounded={'md'} px={1}py={0.2}>{attributes[8].value}</Text>
              </HStack>
            )
            : null
          }
          { attributes[9]
            ? (
              <HStack justifyContent="space-between">
                <Text fontSize={10} color={Const.kWhite}>{attributes[9].trait_type}:</Text>
                <Text fontSize={10} color={Const.kDarkGreen} bg={Const.kYellow} rounded={'md'} px={1}py={0.2}>{attributes[9].value}</Text>
              </HStack>
            )
            : null
          }
          { attributes[10]
            ? (
              <HStack justifyContent="space-between">
                <Text fontSize={10} color={Const.kWhite}>{attributes[10].trait_type}:</Text>
                <Text fontSize={10} color={Const.kDarkGreen} bg={Const.kYellow} rounded={'md'} px={1}py={0.2}>{attributes[10].value}</Text>
              </HStack>
            )
            : null
          }
          { attributes[11]
            ? (
              <HStack justifyContent="space-between">
                <Text fontSize={10} color={Const.kWhite}>{attributes[11].trait_type}:</Text>
                <Text fontSize={10} color={Const.kDarkGreen} bg={Const.kYellow} rounded={'md'} px={1}py={0.2}>{attributes[11].value}</Text>
              </HStack>
            )
            : null
          }
        </Stack>
      </HStack>
    </Flex>
  );
}

export default ModalAttributes;
