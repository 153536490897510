import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Switch,
  Image,
  Box
} from '@chakra-ui/react';

// import Const from './Constants';

const Style = () => {
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const handleSwitchChange = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const images = [
    'https://storage.googleapis.com/resource.earthaliens.net/gallery/style1.png',
    'https://storage.googleapis.com/resource.earthaliens.net/gallery/base1.png'
  ];

  const imageUrl = isSwitchOn
    ? images[0]
    : images[1];

  useEffect(() => {
    images.forEach(function (item, index) {
      const img = new window.Image();
      img.src = item;
    });
  }, []);

  return (
    <Flex justify="center" align="center" height="100vh" paddingBottom={{ base: '300px', md: '120px' }}>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Text fontSize="16px" textShadow="0 5px #000000">Ready to mint?</Text>
        <Switch
          colorScheme='green'
          marginTop={2}
          marginBottom={3}
          size='lg'
          isChecked={isSwitchOn}
          onChange={handleSwitchChange}
        />
        <Box position="relative">
          <Image
            src={imageUrl}
            width={{ base: '96vw', md: '80vh' }}
            height={{ base: '96vw', md: '80vh' }}
            borderRadius="6px"
            alt="img"
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Style;
