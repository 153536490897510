import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Image,
  Flex
} from '@chakra-ui/react';

import _ from 'lodash';

import Const from './Constants';
import ModalAttributes from './ModalAttributes';

import a0 from './assets/nft/0.json';
import a1 from './assets/nft/1.json';
import a2 from './assets/nft/2.json';
import a3 from './assets/nft/3.json';
import a4 from './assets/nft/4.json';
import a5 from './assets/nft/5.json';
import a6 from './assets/nft/6.json';
import a7 from './assets/nft/7.json';
import a8 from './assets/nft/8.json';
import a9 from './assets/nft/9.json';
import a10 from './assets/nft/10.json';
import a11 from './assets/nft/11.json';
import a12 from './assets/nft/12.json';
import a13 from './assets/nft/13.json';
import a14 from './assets/nft/14.json';
import a15 from './assets/nft/15.json';
import a16 from './assets/nft/16.json';
import a17 from './assets/nft/17.json';
import a18 from './assets/nft/18.json';
import a19 from './assets/nft/19.json';
import a20 from './assets/nft/20.json';
import a21 from './assets/nft/21.json';
import a22 from './assets/nft/22.json';
import a23 from './assets/nft/23.json';
import a24 from './assets/nft/24.json';
import a25 from './assets/nft/25.json';
import a26 from './assets/nft/26.json';
import a27 from './assets/nft/27.json';
import a28 from './assets/nft/28.json';
import a29 from './assets/nft/29.json';
import a30 from './assets/nft/30.json';
import a31 from './assets/nft/31.json';
import a32 from './assets/nft/32.json';
import a33 from './assets/nft/33.json';
import a34 from './assets/nft/34.json';
import a35 from './assets/nft/35.json';

let nfts = [{
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/0.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/0_xs.png',
  attributes: a0.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/1.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/1_xs.png',
  attributes: a1.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/2.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/2_xs.png',
  attributes: a2.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/3.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/3_xs.png',
  attributes: a3.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/4.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/4_xs.png',
  attributes: a4.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/5.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/5_xs.png',
  attributes: a5.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/6.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/6_xs.png',
  attributes: a6.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/7.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/7_xs.png',
  attributes: a7.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/8.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/8_xs.png',
  attributes: a8.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/9.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/9_xs.png',
  attributes: a9.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/10.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/10_xs.png',
  attributes: a10.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/11.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/11_xs.png',
  attributes: a11.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/12.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/12_xs.png',
  attributes: a12.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/13.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/13_xs.png',
  attributes: a13.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/14.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/14_xs.png',
  attributes: a14.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/15.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/15_xs.png',
  attributes: a15.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/16.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/16_xs.png',
  attributes: a16.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/17.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/17_xs.png',
  attributes: a17.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/18.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/18_xs.png',
  attributes: a18.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/19.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/19_xs.png',
  attributes: a19.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/20.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/20_xs.png',
  attributes: a20.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/21.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/21_xs.png',
  attributes: a21.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/22.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/22_xs.png',
  attributes: a22.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/23.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/23_xs.png',
  attributes: a23.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/24.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/24_xs.png',
  attributes: a24.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/25.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/25_xs.png',
  attributes: a25.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/26.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/26_xs.png',
  attributes: a26.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/27.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/27_xs.png',
  attributes: a27.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/28.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/28_xs.png',
  attributes: a28.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/29.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/29_xs.png',
  attributes: a29.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/30.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/30_xs.png',
  attributes: a30.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/31.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/31_xs.png',
  attributes: a31.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/32.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/32_xs.png',
  attributes: a32.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/33.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/33_xs.png',
  attributes: a33.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/34.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/34_xs.png',
  attributes: a34.attributes
}, {
  imageURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/35.png',
  imageXsURL: 'https://storage.googleapis.com/resource.earthaliens.net/gallery/35_xs.png',
  attributes: a35.attributes
}];

// Randomize the order every time
nfts = _.shuffle(nfts);

function Gallery({ setAppMode }) {
  const [selectedNft, setSelectedNft] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [xsLoadedNum, setXsLoadedNum] = useState(0);

  const openModal = (nft) => {
    setSelectedNft(nft);
    setIsOpen(true);
  };

  const closeModal = () => {
    setSelectedNft(null);
    setIsOpen(false);
  };

  const handleMintYours = () => {
    setAppMode(Const.sMint);
  };

  useEffect(() => {
    nfts.forEach(function (nft, index) {
      const img = new window.Image();
      img.onload = () => {
        setXsLoadedNum((prevXsLoadedNum) => {
          return prevXsLoadedNum + 1;
        });
      };
      img.src = nft.imageXsURL;
    });
  }, []);

  useEffect(() => {
    if(xsLoadedNum >= 36) {
      nfts.forEach(function (nft, index) {
        const img = new window.Image();
        img.src = nft.imageURL;
      });
    }
  }, [xsLoadedNum]);

  return (
    <>
      <Box width="100%" p={4} overflowY="auto" maxHeight="90vh">
        <Grid
          templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(6, 1fr)' }}
          gap={4}
        >
          {nfts.map((nft, index) => (
            <GridItem
              key={index}
              colSpan={1}
              width="100%"
              paddingBottom="100%"
              position="relative"
            >
              <Box
                bg="gray.100"
                backgroundImage={nft.imageXsURL}
                backgroundSize="cover"
                backgroundPosition="center"
                width="100%"
                height="100%"
                borderRadius="md"
                position="absolute"
                top={0}
                left={0}
                onClick={() => openModal(nft)}
                cursor="pointer"
              />
            </GridItem>
          ))}
        </Grid>
      </Box>

      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent bg={Const.kDarkGreen} maxWidth={{ base: '96vw', md: '64vw', lg: '42vw' }} maxHeight={'100vh'}>
          <ModalHeader
            fontSize={12}
            color={Const.kWhite}
          >
            <Flex justifyContent="space-between" width="100%">
              <Button
                backgroundColor={Const.kGreen}
                borderRadius="5px"
                boxShadow="0px 2px 2px 1px #0F0F0F"
                color={Const.kDarkGreen}
                cursor="pointer"
                fontSize="12px"
                fontWeight="normal"
                paddingX={4}
                maxHeight={8}
                _focus={{ outline: 'none', boxShadow: 'none' }}
                onClick={handleMintYours}
              >
                Mint
              </Button>
              <Button
                backgroundColor={Const.kGreen}
                borderRadius="5px"
                boxShadow="0px 2px 2px 1px #0F0F0F"
                color={Const.kDarkGreen}
                cursor="pointer"
                fontSize="12px"
                fontWeight="normal"
                size="10"
                paddingX={3}
                _focus={{ outline: 'none', boxShadow: 'none' }}
                onClick={closeModal}
              >
                X
              </Button>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Image
              src={selectedNft ? selectedNft.imageURL : null}
              borderRadius="md"
              mx="auto"
            />
          </ModalBody>
          <ModalFooter>
            <ModalAttributes attributes={selectedNft ? selectedNft.attributes : null}/>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Gallery;
