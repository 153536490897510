import React from 'react';
import { Box, Flex, Text, Avatar, Grid, GridItem } from '@chakra-ui/react';

import Hov from './assets/avatar/Hov.png';
import Mash from './assets/avatar/Mash.png';
import Armish from './assets/avatar/Armish.png';
import Ars from './assets/avatar/Ars.png';

import Const from './Constants';

const Team = () => {
  return (
    <Flex justify="center" align="center" height="100vh" paddingBottom="150px">
      <Box>
        <Text
          fontSize="24px"
          textShadow="0 5px #000000"
          marginBottom={10}>
          {Const.sTeam}
        </Text>

        <Grid
          h='400px'
          w='400px'
          gap={4}
        >
          <GridItem rowStart={1} colStart={1}>
            <Avatar size='2xl' src={Ars} />
            <Text marginTop={6} whiteSpace="pre-line">{'Arsen\nAvoyan'}</Text>
            <Text fontSize={11} color={Const.kYellow}>Artist</Text>
          </GridItem>
          <GridItem rowStart={2} colStart={1}>
            <Avatar size='2xl' src={Armish} />
            <Text marginTop={6}>Armine Musakhanyan</Text>
            <Text fontSize={11} color={Const.kYellow}>Community</Text>
          </GridItem>
          <GridItem rowStart={1} colStart={2}>
            <Avatar size='2xl' src={Mash}/>
            <Text marginTop={6}>Masha Grigoryan</Text>
            <Text fontSize={11} color={Const.kYellow}>Marketing</Text>
          </GridItem>
          <GridItem rowStart={2} colStart={2}>
            <Avatar size='2xl' src={Hov}/>
            <Text marginTop={6}>Hovhannes Grigoryan</Text>
            <Text fontSize={11} color={Const.kYellow}>Technology</Text>
          </GridItem>
        </Grid>

      </Box>
    </Flex>
  );
};

export default Team;
