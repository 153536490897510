import React, {
  useEffect
} from 'react';
import {
  Box,
  Button,
  IconButton,
  Flex,
  Image,
  Link,
  Spacer,
  useDisclosure,
  Stack,
  HStack
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { logEvent } from 'firebase/analytics';
import { analytics } from './Firebase.js';

import { ethers } from 'ethers';
import Web3Token from 'web3-token';

import Const from './Constants';

import Twitter from './assets/social-media-icons/twitter_32x32.png';
import Instagram from './assets/social-media-icons/instagram_32x32.png';
import EMail from './assets/social-media-icons/email_32x32.png';
// import Discord from './assets/social-media-icons/discord_32x32.png';

const sTwitterURL = 'https://twitter.com/EarthAliensNFT/';
const sInstagramURL = 'https://www.instagram.com/EarthAliensNFT/';
const sEMailURL = 'mailto:humans@earthaliens.net';

const sMediumWhitepaper = 'https://medium.com/@earthaliensnft/earth-aliens-whitepaper-4dfaea993883';

const NavBar = ({ accounts, setAccounts, appMode, setAppMode, refKey, setRefKey, globalToken, setGlobalToken, config }) => {
  const isConnected = Boolean(accounts[0]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if(!refKey && appMode === Const.sEarn && isConnected) {
      getRefKeyForAcc();
    }
  }, [appMode]);

  useEffect(() => {
    if(!refKey && appMode === Const.sEarn && isConnected) {
      getRefKeyForAcc();
    }
  }, [accounts]);

  // TODO: this function is a copy, move to common
  async function isValidToken(token) {
    try {
      logEvent(analytics, 'is_valid_token');

      const { address, body } = await Web3Token.verify(token, {
        // verify that received token is signed only for our domain
        // domain: 'localhost'
        // domain: 'earthaliens.net'
      });
      return address && body;
    } catch (error) {
      logEvent(analytics, 'error_is_valid_token');
    }
    return false;
  }

  async function getRefKeyForAcc() {
    try {
      // If we already have the key, skip this.
      if(refKey) {
        return;
      }

      logEvent(analytics, 'get_ref_key');

      // Connection to MetaMask wallet
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      // TODO make this a function.
      if(!globalToken || !(await isValidToken(globalToken))) { // TODO is valid duplicated.
        // Generating a token with expiration time
        globalToken = await Web3Token.sign(async msg => await signer.signMessage(msg), {
          domain: 'earthaliens.net',
          statement: 'Sign in to earthaliens.net to create a unique referral URL. This request will not trigger a blockchain transaction or cost any gas fees.',
          expires_in: Const.kTokenExpireTime
        });

        setGlobalToken(globalToken);
      }

      // Make a server request here.
      const theURL = (config.prodMode ? config.baseURL : config.localBaseURL) + '/refkey';
      const response = await fetch(theURL, {
        headers: new Headers({
          Authorization: globalToken
        })
      });
      const data = await response.json();
      setRefKey(data.refKey);
    } catch (error) {
      logEvent(analytics, 'error_get_ref_key');
    }
  }

  async function connectAccount() {
    try {
      logEvent(analytics, 'connect_account');

      if(window.ethereum) {
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts'
        });
        setAccounts(accounts);

        logEvent(analytics, 'connect_account_success');
      } else {
        alert('Use the browser in MetaMask app to connect.');
        // TODO: open metamask with deep link? https://dapps-studio.medium.com/web3-login-with-metamask-react-and-node-a966ca4c7d89

        logEvent(analytics, 'connect_account_change_browser');
      }
    } catch (error) {
      console.log(error);
      logEvent(analytics, 'error_connect_account');
    }
  }

  async function toggleAppMode() {
    try {
      logEvent(analytics, 'earn_eth');

      if(appMode === Const.sMint) {
        setAppMode(Const.sEarn);
      } else {
        setAppMode(Const.sMint);
      }
    } catch (error) {
      logEvent(analytics, 'error_earn_eth');
    }
  }

  async function handleEarnings() {
    try {
      logEvent(analytics, 'handle_earnings');

      if(appMode === Const.sEarnings) {
        setAppMode(Const.sEarn);
      } else {
        setAppMode(Const.sEarnings);
      }
    } catch (error) {
      logEvent(analytics, 'error_handle_earnings');
    }
  }

  async function handleLinkClickAbout() {
    try {
      logEvent(analytics, 'handle_about');

      setAppMode(Const.sAbout);

      onClose();
    } catch (error) {
      logEvent(analytics, 'error_handle_about');
    }
  };

  async function handleLinkClickDisclaimer() {
    try {
      logEvent(analytics, 'handle_disclaimer');

      setAppMode(Const.sDisclaimer);

      onClose();
    } catch (error) {
      logEvent(analytics, 'error_handle_disclaimer');
    }
  };

  async function handleLinkClickTeam() {
    try {
      logEvent(analytics, 'handle_team');

      setAppMode(Const.sTeam);

      onClose();
    } catch (error) {
      logEvent(analytics, 'error_handle_team');
    }
  };

  async function handleLinkClickGallery() {
    try {
      logEvent(analytics, 'handle_gallery');

      setAppMode(Const.sGallery);

      onClose();
    } catch (error) {
      logEvent(analytics, 'error_handle_gallery');
    }
  };

  async function handleLinkClick4K() {
    try {
      logEvent(analytics, 'handle_4k');

      setAppMode(Const.s4K);

      onClose();
    } catch (error) {
      logEvent(analytics, 'error_handle_4k');
    }
  };

  /*
  async function handleLinkClickStyle() {
    try {
      logEvent(analytics, 'handle_style');

      setAppMode(Const.sStyle);

      onClose();
    } catch (error) {
      logEvent(analytics, 'error_handle_style');
    }
  };
  */

  return (
    <Box px={1} align="left" bg="rgba(117, 235, 4, 0.07)">
      <Flex padding="25px">
        <Flex align="center">
          <IconButton
            bg={Const.kGreen}
            color={Const.kDarkGreen}
            borderRadius="5px"
            boxShadow="0px 2px 2px 1px #0F0F0F"
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
        </Flex>

        <Flex marginLeft={0} align="center" display={{ base: 'none', md: 'flex' }}>
          <Link _focus={{ outline: 'none' }} href={sTwitterURL} isExternal>
            <Image src={Twitter} boxSize="42px" margin="0 9px" minWidth="42px" />
          </Link>
          <Link _focus={{ outline: 'none' }} href={sInstagramURL} isExternal>
            <Image src={Instagram} boxSize="42px" margin="0 9px" minWidth="42px" />
          </Link>
          <Link _focus={{ outline: 'none' }} href={sEMailURL} isExternal>
            <Image src={EMail} boxSize="42px" margin="0 9px" minWidth="42px" />
          </Link>
          <HStack spacing={1} alignItems={'center'} marginLeft={4}>
            <Link
              onClick={handleLinkClickAbout}
              rounded={'md'}
              px={3}
              py={3}
              fontSize={12}
              userSelect={'none'}
              _focus={{ outline: 'none' }}
              _hover={{ textDecoration: 'none', bg: Const.kDarkGreen }}>
              {Const.sAbout}
            </Link>
            <Link
              onClick={handleLinkClickGallery}
              rounded={'md'}
              px={3}
              py={3}
              fontSize={12}
              userSelect={'none'}
              _focus={{ outline: 'none' }}
              _hover={{ textDecoration: 'none', bg: Const.kDarkGreen }}>
              {Const.sGallery}
            </Link>
            <Link
              onClick={handleLinkClick4K}
              rounded={'md'}
              px={3}
              py={3}
              fontSize={12}
              userSelect={'none'}
              _focus={{ outline: 'none' }}
              _hover={{ textDecoration: 'none', bg: Const.kDarkGreen }}>
              {Const.s4K}
            </Link>
            { config.showTeam
              ? (
                <Link
                  onClick={handleLinkClickTeam}
                  rounded={'md'}
                  px={3}
                  py={3}
                  fontSize={12}
                  userSelect={'none'}
                  _focus={{ outline: 'none' }}
                  _hover={{ textDecoration: 'none', bg: Const.kDarkGreen }}>
                  {Const.sTeam}
                </Link>)
              : null
            }
            <Link
              rounded={'md'}
              px={3}
              py={3}
              fontSize={12}
              userSelect={'none'}
              _focus={{ outline: 'none' }}
              _hover={{ textDecoration: 'none', bg: Const.kDarkGreen }}
              href={sMediumWhitepaper}
              isExternal>
              {Const.sWhitepaper}
            </Link>
            <Link
              onClick={handleLinkClickDisclaimer}
              rounded={'md'}
              px={3}
              py={3}
              fontSize={12}
              userSelect={'none'}
              _focus={{ outline: 'none' }}
              _hover={{ textDecoration: 'none', bg: Const.kDarkGreen }}>
              {Const.sDisclaimer}
            </Link>
          </HStack>
        </Flex>

        <Spacer margin="0 0px" padding="0px" />

        <Flex align="center">
          {
            ((appMode === Const.sEarn || appMode === Const.sEarnings) && globalToken)
              ? (
                <Button
                  backgroundColor={Const.kGreen}
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color={Const.kDarkGreen}
                  cursor="pointer"
                  fontSize="12px"
                  fontWeight="normal"
                  padding="12px"
                  margin="0 5px"
                  onClick={ handleEarnings }
                >{ (appMode === Const.sEarn) ? Const.sEarnings : Const.sEarn }</Button>
              )
              : (<Box />)
          }
          {
            <Button
              backgroundColor={Const.kGreen}
              borderRadius="5px"
              boxShadow="0px 2px 2px 1px #0F0F0F"
              color={Const.kDarkGreen}
              cursor="pointer"
              fontSize="12px"
              fontWeight="normal"
              padding="12px"
              margin="0 5px"
              onClick={toggleAppMode}
            >{ appMode === Const.sMint ? Const.sEarn : Const.sMint }</Button>
          }
          {
            isConnected
              ? (
                /* <Box margin="0 10px">Connected</Box> */
                <Box />
              )
              : (
                <Button
                  backgroundColor={Const.kGreen}
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color={Const.kDarkGreen}
                  cursor="pointer"
                  fontSize="12px"
                  fontWeight="normal"
                  padding="12px"
                  margin="0 5px"
                  onClick={ connectAccount }
                >
                  Connect
                </Button>
              )
          }
        </Flex>
      </Flex>
      {
        isOpen
          ? (
            <Box pb={4} display={{ md: 'none' }}>
              <Stack as={'nav'} spacing={1}>
                <Link
                  onClick={handleLinkClickAbout}
                  rounded={'md'}
                  px={3}
                  py={3}
                  fontSize={12}
                  userSelect={'none'}
                  _focus={{ outline: 'none' }}
                  _hover={{ textDecoration: 'none', bg: Const.kDarkGreen }}>
                  {Const.sAbout}
                </Link>
                <Link
                  onClick={handleLinkClickGallery}
                  rounded={'md'}
                  px={3}
                  py={3}
                  fontSize={12}
                  userSelect={'none'}
                  _focus={{ outline: 'none' }}
                  _hover={{ textDecoration: 'none', bg: Const.kDarkGreen }}>
                  {Const.sGallery}
                </Link>
                <Link
                  onClick={handleLinkClick4K}
                  rounded={'md'}
                  px={3}
                  py={3}
                  fontSize={12}
                  userSelect={'none'}
                  _focus={{ outline: 'none' }}
                  _hover={{ textDecoration: 'none', bg: Const.kDarkGreen }}>
                  {Const.s4K}
                </Link>
                { config.showTeam
                  ? (
                    <Link
                      onClick={handleLinkClickTeam}
                      rounded={'md'}
                      px={3}
                      py={3}
                      fontSize={12}
                      userSelect={'none'}
                      _focus={{ outline: 'none' }}
                      _hover={{ textDecoration: 'none', bg: Const.kDarkGreen }}>
                      {Const.sTeam}
                    </Link>)
                  : null
                }
                <Link
                  rounded={'md'}
                  px={3}
                  py={3}
                  fontSize={12}
                  userSelect={'none'}
                  _focus={{ outline: 'none' }}
                  _hover={{ textDecoration: 'none', bg: Const.kDarkGreen }}
                  href={sMediumWhitepaper}
                  isExternal>
                  {Const.sWhitepaper}
                </Link>
                <Link
                  onClick={handleLinkClickDisclaimer}
                  rounded={'md'}
                  px={3}
                  py={3}
                  fontSize={12}
                  userSelect={'none'}
                  _focus={{ outline: 'none' }}
                  _hover={{ textDecoration: 'none', bg: Const.kDarkGreen }}>
                  {Const.sDisclaimer}
                </Link>
                <HStack spacing={4}>
                  <Link _focus={{ outline: 'none' }} href={sTwitterURL} isExternal>
                    <Image src={Twitter} boxSize="42px" margin="0 9px" />
                  </Link>
                  <Link _focus={{ outline: 'none' }} href={sInstagramURL} isExternal>
                    <Image src={Instagram} boxSize="42px" margin="0 9px" />
                  </Link>
                  <Link _focus={{ outline: 'none' }} href={sEMailURL} isExternal>
                    <Image src={EMail} boxSize="42px" margin="0 9px" />
                  </Link>
                </HStack>
              </Stack>
            </Box>
          )
          : null
      }
    </Box>
  );
};

export default NavBar;
